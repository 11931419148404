import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as Sentry from '@sentry/angular-ivy';
import { EMPTY, mergeMap, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ToastComponent } from '@tk/shared/itera-ui-kit/toast';

import { AlertifyService } from './alertify.service';
import { ToastAlert } from './toast-alert.interface';

@Component({
  selector: 'app-toast-manager',
  templateUrl: './toast-manager.component.html',
  styleUrls: ['./toast-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToastComponent],
})
export class ToastManagerComponent implements OnInit {
  readonly toasts = new Map<string, ToastAlert>();

  private readonly alertifyService = inject(AlertifyService);

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.alertifyService.toasts$
      .pipe(
        mergeMap((toast) => {
          if (toast.message) {
            this.addToast(toast);
          } else {
            this.logNoMessageToast(toast);
          }

          if (toast.ttl) {
            return timer(toast.ttl).pipe(finalize(() => this.dismissToast(toast.id)));
          }

          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  dismissToast(id: string): void {
    this.toasts.delete(id);
    this.cdr.detectChanges();
  }

  private addToast(toast: ToastAlert): void {
    this.toasts.set(toast.id, toast);
    this.cdr.detectChanges();
  }

  private logNoMessageToast(toast: ToastAlert): void {
    Sentry.captureException(new Error(`EMPTY MESSAGE TOAST: ${toast.type}`));
  }
}
