import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ToastType } from '@tk/shared/itera-ui-kit/toast';

import { ToastAlert } from './toast-alert.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertifyService {
  readonly toasts$: Observable<ToastAlert>;

  private readonly _toasts$ = new Subject<ToastAlert>();

  constructor() {
    this.toasts$ = this._toasts$.asObservable();
  }

  positive(message: string, ttl = 5000): void {
    this.createMessage(message, 'positive', ttl);
  }

  negative(message: string, ttl = 30000): void {
    this.createMessage(message, 'negative', ttl);
  }

  notice(message: string, ttl = 30000): void {
    this.createMessage(message, 'notice', ttl);
  }

  informative(message: string, ttl = 30000): void {
    this.createMessage(message, 'informative', ttl);
  }

  private createMessage(message: string, type: ToastType, ttl: number): void {
    this._toasts$.next({
      id: Math.random().toString(),
      type,
      message,
      ttl,
    });
  }
}
